<template>
  <div>
    <div class="dabox">
      <!-- 左边菜单栏 -->
      <div class="leftbox">
        <Card class="paper" dis-hover :bordered="false">
          <div class="fileTit">
            <div>文件列表</div>
            <div class="part">(1份)</div>
          </div>
          <div style="width:130px;height:140px;border:1px solid #E6E7EB"></div>
          <div>新建DOC文档.PDF</div>
        </Card>
        <Card v-if="geren==true" class="seal" dis-hover :bordered="false">
          <Button class="personalSeal personal" @click="sealSeeting">个人印章</Button>
        </Card>
        <Card v-if="qiye==true" class="enterpriseSeal" dis-hover :bordered="false">
          <Button class="personalSeal personal" @click="sealSeetingfirm">企业印章</Button>
        </Card>
        <Card v-if="geren1==true" class="seal" dis-hover :bordered="false">
          <Button class="personalSeal personal" @click="sealSeeting1">个人印章</Button>
        </Card>
        <Card v-if="qiye1==true" class="enterpriseSeal" dis-hover :bordered="false">
          <Button class="personalSeal personal" @click="sealSeetingfirm1">企业印章</Button>
        </Card>
        <div class="flex-btns">
          <Card class="" dis-hover :bordered="false">
            <Button class="operationBtn" @click="backOut">返回</Button>
          </Card>
          <Card class="" dis-hover :bordered="false">
            <Button @click="modal" class="operationBtn">下一步</Button>
          </Card>
        </div>
      </div>
      <!-- 内容显示 -->
<!--      <div :style="'flex-grow:1;background: #f7f7f7;margin-bottom:'+bottom+'px'">-->
        <div style="flex-grow:1;background: #f7f7f7; position: relative">
<!--        <div :style="'flex:1;'">-->
        <!-- end -->
          <div style="position: absolute;left:50%;margin-left:-270px;width: 800px;word-break: break-all;">
<!--          <div>-->
          <div ref="pronbit">
            <!-- 个人签章 start -->
            <div v-if="mySealList.length>0">
              <div v-for="(item,index) in mySealList" :key="index" >
                <div :style="'left:'+item.positionX+'px;top:'+item.positionY+'px;position: absolute;z-index: 100;width: 160px;height: 50px;border: 1px solid #FE0201;margin: 10px;cursor: move;overflow: hidden;'">
                  <div style="display:flex;justify-content: center;line-height:50px;background:white;font-size:18px">
                    个人印章区域
                  </div>
                </div>
                <div :style="'left:'+item.positionX+'px;top:'+item.positionY+'px;position: absolute;z-index: 100;width: 180px;height: 70px;border: 1px dashed #2C8CF0;  display: flex;align-items: center;padding: 10px;cursor: move;'"
                     @mousedown="moveSeal($event,index)">
                  <div style="color: #ffffff;position: absolute;left: 180px;width: 30px;height: 60px;background-color: #2C8CF0;writing-mode: vertical-lr;display: flex;align-items: center;justify-content: center;font-size: 14px;font-weight: bold;cursor: pointer;"
                       @click="closeSeal(index)">重 置
                  </div>
                </div>
              </div>
            </div>
            <!-- 签署 -->
            <div v-if="showSeal1"
                 :style="'left:'+positionX+'px;top:'+positionY+'px;position: absolute;z-index: 100;width: 160px;height: 50px;margin: 10px;cursor: move;overflow: hidden;'">
              <img style="width:100%;height:100%" :src="qianshuImga">
            </div>
            <!-- end -->
            <!-- 企业签章 start -->
            <div v-if="companySealList.length>0">
              <div v-for="(item,index) in companySealList" :key="index + timer">
                <div :style="'left:'+item.positionX+'px;top:'+item.positionY+'px;position: absolute;z-index: 100;width: 160px;height: 160px;border-radius: 50%;border: 1px solid #FE0201;margin: 10px;cursor: move;overflow: hidden;'">
                  <div style="display:flex;justify-content: center;line-height:140px;background:white;font-size:18px">
                    企业印章区域
                  </div>
                </div>
                <div :style="'left:'+item.positionX+'px;top:'+item.positionY+'px;position: absolute;z-index: 100;width: 180px;height: 180px;border: 1px dashed #2C8CF0;  display: flex;align-items: center;padding: 10px;cursor: move;'"
                     @mousedown="moveSealfirm($event,index)">
                  <div
                      style="color: #ffffff;position: absolute;left: 180px;width: 30px;height: 60px;background-color: #2C8CF0;writing-mode: vertical-lr;display: flex;align-items: center;justify-content: center;font-size: 14px;font-weight: bold;cursor: pointer;"
                      @click="closeSealfirm(index)">重 置
                  </div>
                </div>
              </div>
            </div>
            <!-- 签署 -->
            <div v-if="showSealfirm1"
                 :style="'left:'+positionfirmX+'px;top:'+positionfirmY+'px;position: absolute;z-index: 100;width: 140px;height: 140px;border-radius: 50%;border: 1px solid #FE0201;margin: 10px;cursor: move;overflow: hidden;'">
              <img style="width:100%;height:100%" :src="qianshuImga1">
            </div>
            <div v-for="(item,index) in img_list" :key="index" class="bg-img" id="tlinK">
              <img :src="item">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 验证码弹框 -->
    <Modal
        v-model="modal2"
        :mask="true"
        :mask-closable="false"
        title="请输入您的验证码"
        okText="取消"
        cancelText="下一步"
        @on-ok="ok"
        @on-cancel="closeyzm"
        width="480px"
        :styles="{top: '50%',marginTop:'-100px'}">
      <p>验证码将发送到您的手机{{ this.$store.state.userInfo.phone }}</p>
      <div class="flex ptop">
        <div class="font15">验证码：</div>
        <div><Input v-model="yzm" placeholder="请输入验证码"/></div>
        <div>
          <Button @click="yzmbtn" class="ml10">{{ yzmtype == false ? '获取验证码' : '验证码已发送（' + time + 's）' }}</Button>
        </div>
      </div>
      <div class="fenbu" slot="footer">
        <Button @click="ok" class="">下一步</Button>
        <Button @click="quxiaobtn" class="">取 消</Button>
      </div>
    </Modal>
    <!-- 设置签署区完成弹框 -->
    <Modal
        v-model="modal1"
        :mask="true"
        :mask-closable="false"
        title="添加印章状态"
        okText="取消"
        cancelText="下一步"
        @on-cancel="close"
        width="480px"
        :styles="{top: '50%',marginTop:'-100px'}">
      <Icon class="justifycenter" color="#2d8cf0" size='100' type="md-checkmark-circle-outline"/>
      <p class="center">恭喜你，印章添加完成！</p>
      <div class="fenbu" slot="footer">
        <Button @click="cancel" class="center">返回查看</Button>
      </div>
    </Modal>
    <!-- 签署成功 -->
    <Modal
        v-model="modal3"
        :mask="true"
        :mask-closable="false"
        title="系统提示："
        okText="取消"
        cancelText="下一步"
        @on-cancel="close"
        width="480px"
        :styles="{top: '50%',marginTop:'-100px'}">
      <div class="text_tishi">
        <Icon class="justifycenter" color="#2d8cf0" size='30' type="md-checkmark-circle-outline"/>
        <p class="center">恭喜你，签署成功！</p>
      </div>
      <div class="fenbu" slot="footer">
        <Button @click="okbtn" class="center">确 认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {getsms, signdetails ,checksms, updatesign} from '@/api/qianshuy'
import {in_array} from '@/utils/helper'

export default {
  name: 'qianshu',
  props: {
    titleSync: Object
  },
  data() {
    return {
      duanxin: false,
      signlist: {},
      Border: false,
      template_html_content: '',
      //1企业印章  2经办人签字  4短信验证
      yzmtype: false,//开启倒计时
      time: 59,//倒计时
      yzm: '',//验证码
      quyu: 0,//判断 1设置签名区 2直接发起签署
      qiye: false,//企业是否可见
      geren: false,//个人签章是否可见
      qiye1: false,//企业是否可见
      geren1: false,//个人签章是否可见
      modal1: false,// 设置签署去完成章
      modal2: false,// 验证码框
      modal3: false,// 签署成功弹框
      qianming: {},
      qifeng: false, // 是否使用骑缝章
      showSeal: false, // 是否使用个人印章
      showSeal1: false, // 是否使用个人印章
      showSealfirm: false, // 是否使用企业印章
      showSealfirm1: false, // 是否使用企业印章
      positionX: 0, //个人章X坐标
      positionY: 0, //个人章Y坐标
      positionfirmX: 0, //企业章X坐标
      positionfirmY: 0, //企业章Y坐标
      positionXPlace: 0, //骑缝章X坐标
      positionYPlace: 0, //骑缝章Y坐标
      pageSize: 1, //合同张数
      nowPage: 1, //当前页
      qImg: "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1089874897,1268118658&fm=26&gp=0.jpg",
      partType: null,
      qianshuImg: "",
      qianshuImga: "",
      qianshuImga1: "",
      bottom: '',
      image_url:'',
      successModel:false,
      nowWhere:700,
      timer:'12',
      mySealList:[],
      companySealList:[],

    }
  },
  created() {
    this.timer=Date.parse(new Date());
    this.img_list = this.titleSync.image_list
    if (this.titleSync.type == 1) {
      this.partType = this.titleSync.partType
      this.qianshuImg = this.qImg + "?" + this.titleSync.partType
      this.template_html_content = this.titleSync.template_html_content
      this.quyu = this.titleSync.type
      if(this.titleSync.arr.seal_position){
        this.titleSync.arr.seal_position.forEach(item=>{
          item.positionY = Number(item.positionY) + ((item.position_pagenum - 1) * 1132)
          if (item.type === 0) {
            this.mySealList.push(item)
          }else {
            this.companySealList.push(item)
          }
        })
      }
      if (this.titleSync.arr.positionX && this.titleSync.arr.positionX != "" && this.titleSync.arr.positionY && this.titleSync.arr.positionY != "") {
        this.showSeal = true;
        this.positionX = this.titleSync.arr.positionX
        this.positionY = Number(this.titleSync.arr.positionY) + ((this.titleSync.arr.position_pagenum - 1) * 1132)
      }
      if (this.titleSync.arr.positionfirmX && this.titleSync.arr.positionfirmX != "" && this.titleSync.arr.positionfirmY && this.titleSync.arr.positionfirmY != "") {
        this.showSealfirm = true
        this.positionfirmX = this.titleSync.arr.positionfirmX
        this.positionfirmY = Number(this.titleSync.arr.positionfirmY) + ((this.titleSync.arr.position_pagenum - 1) * 1132)
      }
      //判断是否有个人签章
      if (this.titleSync.arr.requirement.length != 0) {
        let requirement = this.titleSync.arr.requirement
        requirement.forEach(item => {
          if (item == 1) {
            this.qiye = true
          }
          if (item == 2) {
            this.geren = true
          }
        });
      }
    }
    if (this.titleSync.type == 2) {
      this.template_html_content = this.titleSync.template_html_content.contract_content;
      let id = {
        id: this.titleSync.id,
        phone: this.$store.state.userInfo.phone
      }
      signdetails(id).then(res => {
        this.signlist = res.data.data
        if (in_array(this.signlist.requirement, [2, 6, 3, 7]) && this.signlist.positionX != '' && this.signlist.positionX != null) {
          this.geren1 = true
          this.positionX = res.data.data.positionX;
          if(res.data.data.position_pagenum > 0 && res.data.contract_imgs.length>0){
            this.positionY = Number(res.data.data.positionY) + ((res.data.data.position_pagenum - 1) * 1132)
          }else {
            this.positionY = res.data.data.positionY;
          }
        }
        if (in_array(this.signlist.requirement, [1, 5, 3, 7]) && this.signlist.positionfirmX != '' && this.signlist.positionfirmX != null) {
          this.qiye1 = true
          this.positionfirmX = res.data.data.positionfirmX;
          if(res.data.data.positionfirm_pagenum > 0 && res.data.contract_imgs.length>0){
            this.positionfirmY = Number(res.data.data.positionfirmY) + ((res.data.data.positionfirm_pagenum - 1) * 1132)
          }else {
            this.positionY = res.data.data.positionY;
          }
          // if(res.data.contract_imgs.length>0){
          //   this.positionfirmY = Number(res.data.data.positionfirmY) + ((res.data.data.positionfirm_pagenum - 1) * 1135)
          // }else {
          //   this.positionfirmY = res.data.data.positionfirmY;
          // }
        }
        if (this.signlist.requirement == 4 || this.signlist.requirement == 5 || this.signlist.requirement == 6 || res.data.data.requirement == 7) {
          if (res.data.data.smsState == 0) {
            this.duanxin = true
          } else {
            this.duanxin = false
          }
          // console.log(this.duanxin);
        }
        this.qianshuImga1 = res.data.gongzhang
        this.qianshuImga = res.data.yinzhang
      }).catch(err => {
        this.$Message.error(err.msg)
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollx, true)
  },
  methods: {
    // 开启个人印章
    sealSeeting() {
      this.bottom = this.$refs.pronbit.clientHeight
      var pageId = document.querySelector('#tlinK')
      this.mySealList.push({
        positionX:pageId.offsetLeft,positionY:pageId.offsetTop + this.nowWhere,type:0,phone:this.titleSync.arr.phone
      })
    },
    // 开启企业印章
    sealSeetingfirm() {
      var pageId = document.querySelector('#tlinK')
      this.companySealList.push({
        positionX:pageId.offsetLeft,positionY:pageId.offsetTop + this.nowWhere,type:1,phone:this.titleSync.arr.phone
      })
    },
    // 开启个人印章
    sealSeeting1() {
      var pageId = document.querySelector('#tlinK')
      if (this.positionX == '') {
        this.positionX = pageId.offsetLeft;
        this.positionY = pageId.offsetTop + this.nowWhere;
        this.showSeal1 = true;
      } else {
        if (this.showSeal1 == true) {
          this.$Message.warning("您已盖过个人印章")
        } else {
          this.$Message.success("盖章成功")
          this.showSeal1 = true;
        }
      }
    },
    // 开启企业印章
    sealSeetingfirm1() {
      var pageId = document.querySelector('#tlinK')
      if (this.positionfirmX == '') {
        this.positionfirmX = pageId.offsetLeft;
        this.positionfirmY = pageId.offsetTop + this.nowWhere;
        this.showSealfirm1 = true;
      } else {
        if (this.showSealfirm1 == true) {
          this.$Message.warning("您已盖过企业印章")
        } else {
          this.$Message.success("盖章成功")
          this.showSealfirm1 = true;
        }
      }
    },
    // 签署成功确认btn
    okbtn() {
      this.modal3 = false
    },
    // 关闭个人签章
    closeSeal(index) {
      this.mySealList.splice(index,1)
    },
    // 关闭企业签章
    closeSealfirm(index) {
      this.companySealList.splice(index,1)
    },
    // 滚动滑轮获取当前页数
    handleScrollx() {
      if (this.$refs.pronbit) {
        let topBounding = Math.abs(this.$refs.pronbit.getBoundingClientRect().top - 110);
        this.nowWhere= topBounding + 500
        this.nowPage = Math.ceil(topBounding / 1100);
      }
    },
    shezhi() {
      this.qianming.positionX = this.positionX
      this.qianming.positionY = this.positionY
      this.$emit('titleSync', this.qianming)
    },
    //点击下一步
    cancel() {
      this.Border = true
      let num = Math.ceil(this.positionY/1132)
      let numElse = Math.ceil(this.positionfirmY/1132)
      let formdata = {
        positionX: this.positionX, //个人章Y坐标
        positionY: this.positionY-(num-1)*1132, //个人章X坐标
        positionfirmX: this.positionfirmX, //企业章X坐标
        positionfirmY: this.positionfirmY-(numElse-1)*1132, //企业章Y坐标
        position_pagenum:num,
        positionfirm_pagenum:numElse,
        name: this.titleSync.arr.name,
        contract_imgs:this.img_list,
        setNow:this.titleSync.setNow
      }
      this.$Message.info('添加成功');
      this.modal1 = false
      console.log(formdata)
      this.$emit('titleSync', formdata)
      // this.time = 59
      // this.yzmtype = false
    },
    //点击关闭模态框
    close() {
      this.modal1 = false
    },
    closeyzm() {
      this.modal2 = false
    },
    //取消btn
    quxiaobtn() {
      this.modal2 = false
    },
    // 个人签章位置
    moveSeal(e,index) {
      let odiv = e.target;
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      var pageId = document.querySelector('#tlinK')
      document.onmousemove = (en) => {
        if (en.clientX - disX <= (pageId.offsetLeft - 20) || en.clientX - disX >= (pageId.offsetLeft + 620) || en.clientY - disY <= pageId.offsetTop || en.clientY - disY >= this.$refs.pronbit.clientHeight - 70) return false
        let left = en.clientX - disX;
        let top = en.clientY - disY;
        this.mySealList[index].positionX = left;
        this.mySealList[index].positionY = top;
        odiv.style.left = left + 'px';
        odiv.style.top = top + 'px';
      };
      document.onmouseup = (e) => {
        console.log(e)
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 企业签章位置
    moveSealfirm(e,index) {
      let odiv = e.target;
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      var pageId = document.querySelector('#tlinK')
      document.onmousemove = (en) => {
        if (en.clientX - disX <= (pageId.offsetLeft - 20) || en.clientX - disX >= (pageId.offsetLeft + 620) || en.clientY - disY <= pageId.offsetTop || en.clientY - disY >= this.$refs.pronbit.clientHeight - 180) return false
        let left = en.clientX - disX;
        let top = en.clientY - disY;
        this.companySealList[index].positionX = left;
        this.companySealList[index].positionY = top;
        odiv.style.left = left + 'px';
        odiv.style.top = top + 'px';
      };
      document.onmouseup = (e) => {
        console.log(e)
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 骑缝章
    movePlace(e) {
      let odiv = e.target;        //获取目标元素
      //算出鼠标相对元素的位置
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {//鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        if (e.clientY - disY <= 0 || e.clientY - disY >= 960) return false
        let top = e.clientY - disY;
        //绑定元素位置到positionX和positionY上面
        this.positionXPlace = top;
        //移动当前元素
        odiv.style.top = top + 'px';
      };
      document.onmouseup = (e) => {
        console.log(e)
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    //设置签署区去下一步
    modal() {
      let foramData = {
        name: this.titleSync.arr.name,
        contract_imgs:this.img_list,
        seal_position:[],
        index:this.titleSync.arr.sealIndex,
        type:this.titleSync.arr.type
      }
      this.mySealList.forEach(item=>{
        let num = Math.ceil(item.positionY/1132)
        item.positionY=item.positionY-(num-1)*1132
        item.position_pagenum = num
        foramData.seal_position.push(item)
      })
      this.companySealList.forEach(item=>{
        let numElse = Math.ceil(item.positionY/1132)
        item.positionY=item.positionY-(numElse-1)*1132
        item.position_pagenum = numElse
        foramData.seal_position.push(item)
      })
      this.$emit('titleSync', foramData)
    },
    //获取验证码按钮
    yzmbtn() {
      if (this.yzmtype == false) {
        getsms({phone: this.$store.state.userInfo.phone, contract_id: this.signlist.contract_id}).then(res => {
          this.$Message.success(res.msg)
        }).catch(err => {
          this.$Message.warning(err.msg)
        })
        this.yzmtype = true
        let timee = setInterval(() => {
          if (this.time == 1) {
            clearInterval(timee)
            this.time = 59
            this.yzmtype = false
          } else {
            this.time--
          }
        }, 1000)
      } else {
        this.$Message.warning(`验证码已发送，请${this.time}秒后重试`)
      }
    },
    //验证验证码是否正确
    ok() {
      if (this.yzm == '') return this.$Message.warning('请填写验证码')
      checksms({
        phone: this.$store.state.userInfo.phone,
        contract_id: this.signlist.contract_id,
        code: this.yzm
      }).then(res => {
        this.$Message.success(res.msg)
        //提交签署
        let data = {
          phone: this.$store.state.userInfo.phone,
          id: this.signlist.contract_id,
          contract_content: this.$refs.pronbit.innerHTML,
          gongzhang: this.qianshuImga1,
          yinzhang: this.qianshuImga
        }
        updatesign(data).then(res => {
          this.modal2 = false
          this.$Message.success(res.msg)
        }).catch(err => {
          this.$Message.error(err.msg)
        })
      }).catch(err => {
        this.$Message.warning(err.msg)
      })
    },
    backOut() {
      let formdata = {
        contract_imgs:this.img_list
      }
      this.$emit('titleSync', formdata)
    },
  }
}
</script>

<style scoped>
.reset {
  margin-top: 50px;
}

.part {
  font-size: 12px;
}

.ptop {
  margin-top: 10px;
}

.fileTit {
  background-color: #2C8CF0;
  color: #ffffff;
  padding: 2px 0 2px 8px;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  align-items: baseline;
}

.perforation {
  border: 1px dashed #FE0201;
  color: #FE0201;
  box-shadow: 0px 0px 10px 2px rgba(254, 2, 1, 0.2);
}

.personal {
  border: 1px solid #2C8CF0;
  color: #2C8CF0;
  box-shadow: 0px 0px 10px 2px rgba(44, 140, 240, 0.2);
}

.paper {
  position: fixed;
  top: 120px;
  z-index: 999;
  margin-left: 20px;
}

.seal {
  position: fixed;
  top: 340px;
  z-index: 999;
  margin-left: 20px;
}

.enterpriseSeal {
  position: fixed;
  top: 420px;
  z-index: 999;
  margin-left: 20px;
}

.perforationSeal {
  position: fixed;
  top: 580px;
  z-index: 999;
  margin-left: 20px;
}

.flex-btns{
  position: fixed;
  bottom: 40px;
  z-index: 999;
  display: flex;
  align-items: center;
}
.nextOpera {
  position: fixed;
  bottom: 110px;
  z-index: 999;
  margin-left: 20px;
}
.backOpera {
  position: fixed;
  bottom: 50px;
  z-index: 999;
  margin-left: 20px;
}
.newOpera {
  position: fixed;
  bottom: 180px;
  z-index: 999;
  margin-left: 20px;
}
.text_tishi {
  display: flex;
  align-items: center;
  justify-content: center;
}

.operationBtn {
  /*margin: 0 20px;*/
  border: 1px solid #2C8CF0;
  color: #2C8CF0;
  box-shadow: 0px 0px 10px 2px rgba(44, 140, 240, 0.2);
}
.operation-new {
  width: 90px;
  height: 40px;
  margin: 0 20px;
  background: #2C8CF0;
  color: white;
  box-shadow: 0px 0px 10px 2px rgba(44, 140, 240, 0.2);
}

.personalSeal {
  width: 130px;
  height: 60px;
}

#app {
  position: relative;
  z-index: 100;
  top: 10px;
  left: 10px;
  width: 150px;
  height: 80px;
  background: #eee;
  border: 1px solid #999;
}

#app1 {
  position: relative;
  z-index: 99;
  top: 10px;
  left: 10px;
  width: 70px;
  height: 70px;
  background: #eee;
  border: 1px solid #999;
}

.shulie {
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 80px;
}

.dabox {
  width: 100%;
  display: flex;
}

.leftbox {
  width: 200px;
  background: white;
  position: fixed;
  height: 100vh;
}

.rightbox {
  width: 250px;
  height: 100vh;
  position: fixed;
  right: 0;
}

.txt {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
}

.dashed {
  width: 150px;
  height: 100px;
  border: 1px dashed #999;
  font-size: 30px;
}

.dashed:hover {
  border: 1px solid #6baef4;
}

.ablso {
  position: absolute;
  bottom: 220px;
}

.pin {
  display: flex;
  justify-content: center;
  z-index: 99;
}

>>> .ivu-modal-header {
  background: #edf4fc;
  border-bottom: none;
  padding-bottom: 0;
}

>>> .ivu-modal-content {
  background: #edf4fc;
  padding: 30px;
}

>>> .ivu-modal-footer {
  border-top: none;
  /* display: flex;
  justify-content: space-around; */
}

>>> .ivu-modal-header-inner {
  font-size: 20px;
}

>>> .ivu-modal-mask {
  background: none;
}

.fenbu {
  display: flex;
  justify-content: space-around;
}

.justifycenter {
  display: flex;
  justify-content: center;
}
.bg-img {
  width: 800px;
  height: 1132px;
  margin: auto;
  background: white;
  border-bottom: 1px solid #8a8f97;
  box-sizing: border-box;
  box-shadow:0 10px 10px rgba(0, 0, 0, .3);
}
.bg-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
  display: inline-block;
}
</style>
<style>
.new-more-style {
  padding: 40px 52px;
  box-sizing: border-box;
}
.new-more tr {
  height: 30px;
}

.new-more table {
  width: 100%;
  border: 1px solid #777777;
}

.new-more td, .new-more th {
  border: 1px solid #777777;
}
.MsoNormalTable{
  margin-left: unset!important;
}
</style>

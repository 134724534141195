<template>
  <div class="page-wrap">
    <div v-if="showMe">
      <div v-if="sign">
        <qianshu @titleSync="goToView1" :titleSync.sync='data2'></qianshu>
      </div>
      <div v-else>
        <div class="choose-first">
          <div class="top-change" @click="goBack">
            <div class="l-where">
              发起签署<span>/</span>发起合同
            </div>
            <div class="r-back" >
              <i class="iconfont icon-fanhui"></i>
            </div>
          </div>
          <div class="top-nav">
            <div class="nav-title">基本任务信息</div>
            <div class="input-group flex">
              <div class="wrap flex">
                <div class="label">任务名称：</div>
                <div class="r-find">
                  <Input v-model="formValidate.contract_name" placeholder="请输入任务名称" style="width: 240px" />
                </div>
              </div>
              <div class="wrap flex">
                <div class="label">截至日期：</div>
                <div class="r-find">
                  <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end" placeholder="请选择截至日期" style="width: 240px"></DatePicker>
                </div>
              </div>
              <div class="wrap flex">
                <div class="label">合同标签：</div>
                <div class="r-find">
                  <Select v-model="formValidate.label_id" multiple style="width:240px">
                    <Option v-for="item in ticketList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                  </Select>
                </div>
              </div>
              <div class="wrap flex">
                <Checkbox v-model="formValidate.authentication">合同需要用户认证</Checkbox>
                <Checkbox v-model="formValidate.smsNotice">短信通知用户</Checkbox>
              </div>
            </div>
          </div>
          <div class="have-sel">
            <div class="middle-nav">
              <div class="nav-title">
                选择模板
                <Select @on-change="showMsg" v-model="formValidate.template_id" style="width:210px;margin-left: 12px" filterable  clearable @on-clear="clearTemplate" >
                  <Option v-for="item in tempList" :value="item.id" :key="item.id ">{{ item.template_name }}</Option>
                </Select>
              </div>
              <div class="upload-style-i" v-if="formValidate.template_html_content != ''">
                <div class="up-del middle-finish">
                  <img src="../../../assets/imgs/123.png" height="58" width="46"/>
                  <div class="finish-success">上传成功</div>
                  <div class="change-status flex-c">
                    <div class="see flex-c" @click="previewIt">预览</div>
                    <div class="see flex-c" @click="delTem">删除</div>
                  </div>
                </div>
              </div>
              <div class="upload-style-i" v-if="formValidate.template_html_content == ''">
                <Upload
                        :show-upload-list="false"
                        :headers='headers'
                        :action="imageAction"
                        class="up-del flex-c"
                        :on-format-error="handleFormatError"
                        :format="['jpg','jpeg','png','doc','docx','pdf']"
                        :on-success="upload_success">
                  <div class="up-img">
                    <img src="../../../assets/imgs/12.png"/>
                  </div>
                  <div class="up-btn flex-c">选择文件</div>
                </Upload>
              </div>
            </div>
            <div class="middle-r">
              <div class="nav-title">上传附件<span class="nav-tips">仅供查看，无法签署</span></div>
              <div style="display: flex;margin-top: 20px">
                <div class="upload-style-i" v-if="accessories_url != ''">
                  <div class="up-del middle-finish">
                    <img src="../../../assets/imgs/123.png" height="58" width="46"/>
                    <div class="finish-success">上传成功</div>
                    <div class="change-status flex-c">
                      <div class="see flex-c" @click="yulanPic">预览</div>
                      <div class="see flex-c" @click="delPic">删除</div>
                    </div>
                  </div>
                </div>
                <div class="upload-style-i" v-if="accessories_url == ''">
                  <Upload
                          :show-upload-list="false"
                          :headers='headers'
                          :name="'img_file'"
                          :action="pictureAction"
                          class="up-del flex-c"
                          :on-format-error="handleFormatError"
                          :format="['jpg','jpeg','png','doc','docx','pdf']"
                          :on-success="upload_success_else">
                    <div class="up-img">
                      <img src="../../../assets/imgs/12.png"/>
                    </div>
                    <div class="up-btn flex-c">选择附件</div>
                  </Upload>
                </div>
                <div class="else-up">
                  <div class="pic-wrap">
                    <div class="img-list" v-for="(item,index) in accessoriesList" :key="index">
                      <img :src="item">
                      <div class="close" @click="delThis(index)"><Icon type="ios-close-circle-outline" size="30" /></div>
                    </div>
                    <div v-if="accessoriesList.length <=4">
                      <Upload
                              :show-upload-list="false"
                              :headers='headers'
                              :name="'img_file'"
                              :action="pictureAction"
                              :on-format-error="handleFormatError"
                              :max-size="3072"
                              :on-exceeded-size="errSize"
                              :format="['jpg','jpeg','png',]"
                              :on-success="uploadImgSuccess">
                        <div class="upload-style">
                          <Icon type="ios-add" size="60"/>
                          <Button size="small" type="primary">上传图片</Button>
                        </div>
                      </Upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="company"  v-if="list1.length > 0">
              <div class="nav-title">企业</div>
              <div class="more-del" v-for="(item,index) in list1" :key="index">
                <div class="input-nav flex">
                  <div class="wrap flex">
                    <div class="label">参与方：</div>
                    <div class="r-find">
                      <Input v-model="item.participants" placeholder="请输入参与方" style="width: 200px"/>
                    </div>
                  </div>
                  <div class="wrap flex">
                    <div class="label">企业名称：</div>
                    <div class="r-find">
                      <Input v-model="item.prise_name" placeholder="请输入企业名称" style="width: 200px"/>
                    </div>
                  </div>
                  <div class="wrap flex">
                    <div class="label">经办人姓名：</div>
                    <div class="r-find">
                      <Input v-model="item.name" placeholder="请输入经办人姓名" style="width: 200px"/>
                    </div>
                  </div>
                  <div class="wrap flex">
                    <div class="label">经办人手机号：</div>
                    <div class="r-find">
                      <Input v-model="item.phone" placeholder="请输入经办人手机号" style="width: 200px"/>
                    </div>
                  </div>
                </div>
                <div class="check-nav">
                  <div class="wrap flex">
                    <div class="label">签署要求：</div>
                    <CheckboxGroup v-model="item.requirement" style="margin-right: 40px">
                      <Checkbox :label="1">企业公章</Checkbox>
                      <Checkbox :label="2">经办人签字</Checkbox>
                      <Checkbox :label="4">短信验证</Checkbox>
                    </CheckboxGroup>
                  </div>
                  <div class="wrap flex">
                    <div class="label">需支付</div>
                    <div class="r-find">
                      <Input v-model="item.money" type="number" placeholder="（选填）" style="width: 120px;margin-left: 12px;margin-right: 12px" />
                    </div>
                    <div class="label">元</div>
                  </div>
                  <div class="last-btn flex-c" @click="toSign(item,index)" v-if="jurisdictionList.indexOf('launch-many-sign') !== -1">设置签名区</div>
                </div>

                <div class="check-nav" v-if="jurisdictionList.indexOf('template-components') !== -1">
                  <div   v-if="enterpriseTemplate" class="wrap flex">
                    <div  class="label">模板配置：</div>
                    <CheckboxGroup  v-if='showmoban' v-model="item.template_cg_arr"  style="margin-right: 40px">
                      <Checkbox v-for="item in componentsArr" :label="item.id"  :value="item.id" :key="item.id"> {{ item.title }}</Checkbox>
                    </CheckboxGroup>
                  </div>
                </div>

                <div class="font flex-c" @click="clearTable('1',index)">
                  <i class="iconfont icon-shanchu"></i>
                </div>
              </div>
            </div>
            <div class="company" v-if="list.length > 0">
              <div class="nav-title">个人</div>
              <div class="more-del" v-for="(item , index) in list" :key="index">
                <div class="input-nav flex">
                  <div class="wrap flex">
                    <div class="label">参与方：</div>
                    <div class="r-find">
                      <Input v-model="item.participants" placeholder="请输入参与方" style="width: 200px"/>
                    </div>
                  </div>
                  <div class="wrap flex">
                    <div class="label">真实姓名：</div>
                    <div class="r-find">
                      <Input v-model="item.name" placeholder="请输入真实姓名" style="width: 200px"/>
                    </div>
                  </div>
                  <div class="wrap flex">
                    <div class="label">手机号：</div>
                    <div class="r-find">
                      <Input v-model="item.phone" placeholder="请输入手机号" style="width: 200px"/>
                    </div>
                  </div>
                </div>
                <div class="check-nav">
                  <div class="wrap flex">
                    <div class="label">签署要求：</div>
                    <CheckboxGroup v-model="item.requirement" style="margin-right: 40px">
                      <Checkbox :label="2">个人签字</Checkbox>
                      <Checkbox :label="4">短信验证</Checkbox>
                    </CheckboxGroup>
                  </div>
                  <div class="wrap flex">
                    <div class="label">需支付</div>
                    <div class="r-find">
                      <Input v-model="item.money" type="number" placeholder="（选填）" style="width: 120px;margin-left: 12px;margin-right: 12px" />
                    </div>
                    <div class="label">元</div>
                  </div>
                  <div class="last-btn flex-c" @click="toSign(item,index)" v-if="jurisdictionList.indexOf('launch-many-sign') !== -1">设置签名区</div>
                </div>
                <div v-if="jurisdictionList.indexOf('template-components') !== -1">
                  <div v-if="personalTemplate" class="check-nav">
                    <div  class="wrap flex"  >
                      <div  class="label">模板配置：</div>
                      <CheckboxGroup v-if='showmoban' v-model="item.template_cg_arr"  style="margin-right: 40px">
                        <Checkbox v-for="item in personalArr" :label="item.id"  :value="item.id" :key="item.id"> {{ item.title }}</Checkbox>
                      </CheckboxGroup>
                    </div>
                  </div>
                </div>
                <div class="font flex-c" @click="clearTable('2',index)">
                  <i class="iconfont icon-shanchu"></i>
                </div>
              </div>

            </div>
          </div>
          <div class="moreSign flex-b">
            <div class="select-it flex-c" @click="checkCompany">
              <div class="icon-pic iconfont icon-qiye"></div>
              <div class="pic-name">添加企业</div>
            </div>
            <div class="select-it flex-c" @click="checkPerson">
              <div class="icon-pic iconfont icon-tianjiageren"></div>
              <div class="pic-name">添加个人</div>
            </div>
            <div class="select-it flex-c" @click="chooseHave = true">
              <div class="icon-pic iconfont icon-tianjiamoren"></div>
              <div class="pic-name">添加默认甲方信息</div>
            </div>
          </div>
          <div class="submit-btn submit-btn-else flex-c" @click="subInfo">
            <i class="iconfont icon-faqiqianshu1"></i>
            <span>发起签署</span>
          </div>
        </div>
        <div class="use-table" v-if="chooseHave">
          <div class="pop"></div>
          <div class="pop-inside fixed">
            <Tabs :value="elsePrams.type" style="margin-top: 20px" @on-click="changeTab">
              <TabPane label="个人" name="1"></TabPane>
              <TabPane label="企业" name="2"></TabPane>
            </Tabs>
            <div style="display: flex;margin-top: 20px;margin-bottom: 20px">
              <div style="display: flex;align-items: center;margin-right: 20px">
                <Input v-model="elsePrams.title" style="width: 240px" enter-button placeholder="请输入姓名、电话、企业名称进行搜索"/>
              </div>
              <div>
                <Button type="primary" @click="getDetail">搜索</Button>
              </div>
            </div>
            <Table :columns="detailColumns" :data="detailList" @on-select="selectOnce" @on-select-all="allSelect">
              <template slot-scope="{ row }" slot="type">
                <div>{{row.type == '0' ? '个人' : '企业'}}</div>
              </template>
            </Table>
            <br/>
            <Page :total="elsePrams.total"
                  :current="elsePrams.page"
                  style="text-align:right"
                  show-elevator show-total
                  @on-change="pageChange"
                  :page-size="elsePrams.limit"/>
            <div class="close-it" @click="chooseHave = false"><Icon type="md-close" size="25" /></div>
            <div class="choose-it flex-c" @click="submitList">确认</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Preview ref="preview" @titleSync="seeBack"></Preview>
    </div>
  </div>
</template>

<script>
  import {getCookies} from '@/utils/helper'
  import config from '@/config/index'
  import Preview from '../../../components/preview/index'
  import qianshu from '../../../components/qianshu/qianshu'
  import {info,templist,deletetmp, edittempupdate, delEnclosure, contractadd, signatoryList, searchList} from '../../../api/interFace'
  export default {
    name: "index",
    components:{
      Preview,
      qianshu
    },
    data(){
      return{
        sign:false,
        enterpriseTemplate:false,
        personalTemplate:false,
        showmoban:false,
        list:[],
        list1:[],
        ticketList:[],
        componentsArr:[],
        personalArr:[],
        data2:{},
        showMe:true,
        elsePrams: {
          limit: 5,
          page: 1,
          total: 0,
          title: '',
          type: '1',
        },
        detailColumns: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '#',
            key: 'id',
            width: 80,
            align: 'center'
          },
          {
            title: '参与方',
            key: 'participants',
            align: 'center'
          },
          {
            title: '姓名',
            key: 'name',
            align: 'center'
          },
          {
            title: '电话',
            key: 'phone',
            align: 'center'
          },
          {
            title: '所属主体',
            slot: 'type',
            align: 'center'
          },
          {
            title: '企业名称',
            key: 'prise_name',
            align: 'center'
          },
        ],
        detailList: [],
        formValidate: {
          authentication: false,
          smsNotice: false,
          template_html_content: '',
          template_html: '',
          template_id: '',
          contract_name: '',
          contract_content: '',
          contract_annex: [],
          contract_imgs: [],
          components_arr:[],
          part: [],
          start_time: '',
          end_time: '',
          document_type: '',
          upload_type: 2,
          label_id:''
        },
        components_arr:[],
        tempList:[],
        accessories_file:'',
        accessories_url:'',
        chooseHave:false,
        accessoriesList:[],
        accessories_img:[],
        requirementElse:[],
        imageAction:config.apiUrl + '/adminapi/common/uploads',
        pictureAction:config.apiUrl + '/adminapi/contract/upload_enclosure',
        headers:{'Authori-zation' : getCookies('token')},
        modelSelect:'',
        showType:'',
        creatList: [],
        jurisdictionList:[],
        timeVal:[],
        clickNum: 0,
        submiting:false
      }
    },
    created() {
      this.getTem()
      this.getDetail()
      this.getTickets()
      this.jurisdictionList = this.$store.state.auth
    },
    methods:{
      previewIt(){
        this.showMe = false
        this.$nextTick(()=>{
          this.$refs.preview.openIt(this.formValidate)
        })
      },
      seeBack(){
        this.showMe = true
      },
      toSign(row,index){
        if (!row.participants) return this.$Message.warning('请填写参与方')
        if (!row.name) return this.$Message.warning('请输入姓名')
        if (!row.phone) return this.$Message.warning('请输入手机号')
        if (!row.requirement || row.requirement.length == 0) return this.$Message.warning('请选择签署要求')
        if (row.money_check == 1 && (!row.money || isNaN(row.money))) return this.$Message.warning('请输入金额');
        let components_arr =[]
        if (typeof row.template_cg_arr != "undefined") {
          row.template_cg_arr.forEach( item1 =>{
            this.formValidate.components_arr.forEach( item2 =>{
              if(item1==item2.id){
                components_arr.push(item2)
              }
            })
            if(item1==[]){
              components_arr=[]
            }
          })
        }

        this.new_content = this.formValidate.template_html_content
        this.data2.template_html_content = this.new_content
        this.data2.partType = this.clickNum
        this.data2.image_list = this.formValidate.contract_imgs
        this.data2.type = 1
        this.data2.arr = row
        this.data2.components_arr = components_arr
        this.sign = true
        this.data2.arr.sealIndex = index
      },
      goToView1(e) {
        this.sign = false
        if (typeof  e.type == "undefined") {
          return
        }
        if(e.type === 1){
          this.list1[e.index].seal_position = e.seal_position
        }else {
          this.list[e.index].seal_position = e.seal_position
        }
        // e.seal_position.forEach(item=>{
        //   this.arr.push(item)
        // })//
      },
      changeTime(e){
        this.formValidate.start_time = e[0]
        this.formValidate.end_time =  e[1]
      },
      //选择模板
      showMsg(e){
        this.personalTemplate=false
        this.enterpriseTemplate=false
        if(!e){
          return
        }
        const msg = this.$Message.loading({
          content: '文件较大正上传中，请等待...',
          duration: 0
        });
        info({id:e}).then(async res => {
          this.formValidate.document_type = res.data.document_type
          this.formValidate.upload_type = res.data.upload_type
          this.formValidate.template_html_content = res.data.template_html_content
          this.formValidate.contract_content = res.data.template_content
          this.formValidate.contract_imgs = res.data.contract_imgs
          this.formValidate.components_arr=res.data.components_arr
          this.componentsArr=res.data.components_arr
          this.personalArr=res.data.components_arr
          console.log("res.data.components_arr",res.data.components_arr)
          //判断是否为空数组 如果是 模板配置不显示
          if(this.formValidate.components_arr.length != 0){
            this.personalTemplate=true
            this.enterpriseTemplate=true
          }
          this.showmoban=true
          setTimeout(msg, 100);
        })
      },
      getTickets(){
        searchList().then(res=>{
          this.ticketList = res.data || []
        }).catch(res=>{
          console.log(res)
        })
      },
      yulanPic(){
        window.open(this.accessories_file)
      },
      delPic() {
        delEnclosure({img_url:this.accessories_url}).then(res=>{
          this.$Message.success(res.msg);
          this.accessories_file = ''
          this.accessories_url = ''
        })
      },
      changeTab(e){
        this.elsePrams.type = e
        this.getDetail()
      },
      selectOnce(e){
        this.creatList = []
        e.forEach(item=>{
          if(item.requirement === 1 ) {
            item.requirement = [1]
          }else if(item.requirement === 2 ) {
            item.requirement = [2]
          }else if(item.requirement === 3){
            item.requirement = [1, 2]
          }else if(item.requirement === 4 ) {
            item.requirement = [4]
          }else if(item.requirement === 5 ) {
            item.requirement = [1, 4]
          }else if(item.requirement === 6 ) {
            item.requirement = [2, 4]
          }else if(item.requirement === 7 ) {
            item.requirement = [1, 2, 4]
          }
          item.money_check = 0
          item.seal_position = []
          item.template_cg_id = []
          this.creatList.push(item)
        })
      },
      allSelect(e){
        e.forEach(item=>{
          if(item.requirement === 1 ) {
            item.requirement = [1]
          }else if(item.requirement === 2 ) {
            item.requirement = [2]
          }else if(item.requirement === 3){
            item.requirement = [1, 2]
          }else if(item.requirement === 4 ) {
            item.requirement = [4]
          }else if(item.requirement === 5 ) {
            item.requirement = [1, 4]
          }else if(item.requirement === 6 ) {
            item.requirement = [2, 4]
          }else if(item.requirement === 7 ) {
            item.requirement = [1, 2, 4]
          }
          item.money_check = 0
          item.seal_position = []
          item.template_cg_id = []
        })
        this.creatList = e
      },
      getDetail(){
        signatoryList(this.elsePrams).then(res => {
          this.detailList = res.data.data || []
          this.elsePrams.total = res.data.total
        }).catch(res => {
          console.log(res)
        })
      },
      pageChange(index) {
        this.elsePrams.page = index
        this.getDetail()
      },
      submitList(){
        if(this.elsePrams.type == 1){
          this.list=this.creatList
        }else {
          this.list1=this.creatList
        }
        this.chooseHave = false
      },
      checkCompany() {
        console.log("this.enterpriseTemplate",this.enterpriseTemplate)
        // if( this.enterpriseTemplate){
        //    this.enterpriseTemplate=false
        // }else{
        //    this.enterpriseTemplate=true
        // }
        this.list1.push({
          prise_name: '',
          type: 1,
          money_check: 0,
          part_type: 0,
          seal_position:[],
          setNow: this.timer,
          template_cg_id:[],
          template_cg_arr:[],
          positionX: '',//个人章X坐标
          positionY: '',//个人章y坐标
          positionfirmX: '',//企业章X坐标
          positionfirmY: ''//企业章y坐标
        })
      },
      checkPerson() {
        //   if( this.personalTemplate){
        //    this.personalTemplate=true
        // }else{
        //    this.personalTemplate=false
        // }
        this.list.push({
          type: 0,
          money_check: 0,
          seal_position:[],
          template_cg_id:[],
          template_cg_arr:[],
          part_type: 0,
          positionX: '',
          positionY: '',
          setNow: this.timer
        })
      },
      clearTable(type,index){
        if(type === '1') {
          this.list1.splice(index, 1);
        }else {
          this.list.splice(index, 1);
        }
      },
      clearTemplate(){
        //模板样式删除
        this.showmoban=false
        this.personalTemplate = false
        this.enterpriseTemplate = false
        this.formValidate.template_id = ''
        this.formValidate.template_html_content = ''
        this.formValidate.contract_content = ''
        this.formValidate.contract_annex.length = 0
      },
      delTem() {
        console.log('template_id:', this.formValidate.template_id)
        if (this.formValidate.template_id == '' || this.formValidate.template_id == undefined) {
          deletetmp({contract_path: this.formValidate.contract_imgs}).then(res => {
            this.$Message.success(res.msg)
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        }

        this.clearTemplate();
      },
      getTem(){
        templist({status:'all'}).then(async res => {
          this.tempList = res.data.data
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      },
      upload_success_else (e){
        this.accessories_file = e.data.img_url
        this.accessories_url = e.data.src
        this.$Message.destroy()
      },
      uploadImgSuccess (e) {
        this.accessoriesList.push(e.data.img_url)
        this.accessories_img.push(e.data.src)
        this.$Message.destroy()
        this.$Message.success('上传成功');
      },
      goBack(){
        this.$emit('goBack')
      },
      // 文件类型上传失败
      handleFormatError() {
        this.$Message.warning('暂不支持上传此类型文件')
      },
      // 判断类型上传文件
      upload_success(e) {
        const msg = this.$Message.loading({
          content: '文件上传中，请等待...',
          duration: 0
        });
        this.formValidate.contract_content = e.data.src
        //获取合同 HTML
        edittempupdate({path: e.data.src}).then(async res => {
          this.formValidate.document_type = res.data.document_type
          this.formValidate.upload_type = res.data.upload_type
          // this.formValidate.template_html = res.data.path
          this.formValidate.template_html_content = res.data.html
          this.formValidate.contract_imgs = res.data.imgs
          this.formValidate.contract_pdf = res.data.path
          this.$Message.success(e.msg)
          setTimeout(msg, 100);
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      },
      errSize(){
        this.$Message.error('上传图片不得超过3M');
      },
      delThis(index){
        let val = this.accessories_img[index]
        delEnclosure({img_url:val}).then(res=>{
          this.$Message.success(res.msg);
          this.accessoriesList.splice(index,1)
          this.accessories_img.splice(index,1)
        })
      },
      subInfo() {
        if (this.formValidate.contract_name == '') return this.$Message.warning('请填写合同名称')
        if (this.formValidate.start_time == '' || this.formValidate.start_time == 'NaN-NaN-NaN') return this.$Message.warning('请选择时间')
        if (this.formValidate.end_time == '' || this.formValidate.end_time == 'NaN-NaN-NaN') return this.$Message.warning('请选择时间')
        if (this.formValidate.contract_content == '') return this.$Message.warning('请上传文件')
        this.formValidate.part = [];
        let error_msg = "";
        if (this.list.length > 0) {
          for ( let i in this.list ) {
            let item = this.list[i]
            if (item.money>0) {
              item.money_check = 1
            }
            if (!item.participants) {error_msg = '请填写参与方'; break; }
            if (!item.name) {error_msg = "请输入姓名"; break;}
            if (!item.phone || isNaN(item.phone)){ error_msg = "请输入正确的手机号"; break;}
            if (!item.requirement || item.requirement.length == 0) { error_msg = "请选择签署要求"; break;}
            if (typeof item.template_cg_arr != 'undefined' && item.template_cg_arr.length > 0) {
              item.template_cg_arr.forEach( item1 =>{
                this.formValidate.components_arr.forEach( item2 =>{
                  if(item1==item2.id){
                    item.template_cg_id.push(item2)
                  }
                })
                if(item1==[]){
                  item.template_cg_id=[]
                }
              })
            }
            this.formValidate.part.push(item)
          }
        }
        if (this.list1.length > 0) {
          for(let j in this.list1) {
            let item = this.list1[j]
            if (item.money>0) {
              item.money_check = 1
            }
            if (!item.participants){ error_msg = "请填写参与方"; break;}
            if (!item.prise_name){ error_msg = "请填写企业名称"; break;}
            if (!item.name) {error_msg = "请输入姓名"; break;}
            if (!item.phone){ error_msg = "请输入手机号"; break;}
            if (!item.requirement || item.requirement.length == 0){ error_msg = "请选择签署要求"; break;}

            if (typeof item.template_cg_arr != 'undefined' &&  item.template_cg_arr.length>0) {
              item.template_cg_arr.forEach( item1 =>{
                this.formValidate.components_arr.forEach( item2 =>{
                  if(item1==item2.id){
                    item.template_cg_id.push(item2)
                  }
                })
                if(item1==[]){
                  item.template_cg_id=[]
                }
              })
            }

            this.formValidate.part.push(item)
          }
        }
        if (error_msg != "") {
          this.$Message.warning(error_msg);
          return;
        }
        if (this.formValidate.part.length == 0) {
          this.$Message.warning('请添加参与者');
          return;
        }
        this.formValidate.accessories_img = String(this.accessories_img)
        this.formValidate.accessories_url = this. accessories_url

        if (this.submiting) {
          return
        }

        this.submiting = true
        this.$Message.loading({
          content: '发起中',
          duration: 0
        });
        contractadd(this.formValidate).then(async res => {
          this.submiting = false
          this.$Message.destroy()
          this.$Message.success(res.msg)
          this.restetData();
        }).catch(res => {
          this.submiting = false
          this.$Message.destroy()
          this.$Message.error(res.msg)
        })
      },
      restetData() {
        this.formValidate = {
          label_id:"",
          template_html_content:"",
          template_html:"",
          template_id:"",
          contract_name:"",
          contract_content:"",
          contract_annex:[],
          part:[],
          start_time:"",
          end_time:"",
          template_cg_id:[],
          template_cg_arr:[],
          document_type:"",
          upload_type:2,
          authentication:false,
          accessories_id:0
        }
        this.personalArr=[]
        this.componentsArr=[]
        this.enterpriseTemplate=false
        this.personalTemplate=false
        this.list = [];
        this.list1 = [];

        this.timeVal = []
        this.accessoriesList = []
        this.accessories_img = []
        this.accessories_url = ''
        this.accessories_file = ''
        console.log('this.enterpriseTemplate', this.enterpriseTemplate)
      },
    }
  }
</script>

<style scoped lang="scss">
  .page-wrap {
    .top-change {
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 30px;
      margin-bottom: 20px;
      cursor: pointer;
      box-sizing: border-box;
      height: 60px;
      .l-where {
        //position: absolute;
        //left: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 18px;
        font-weight: bold;
        color: #1B2742;
        span {
          color: #4877E8;
        }
      }
      .r-back {
        .iconfont {
          //position: absolute;
          //right: 30px;
          //top: 50%;
          //transform: translateY(-50%);
          font-size: 26px;
          cursor: pointer;
          &:hover {
            color: #4877E8;
          }
        }
      }
    }
    .first-in {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      .check-item {
        width: 280px;
        height: 340px;
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        border: 2px solid #fff;
        box-sizing: border-box;
        &:hover {
          border: 2px solid #4877E8;
        }
        .iconfont {
          font-size: 80px;
          color: #6D92ED;
        }
        .word {
          margin-top: 12px;
          font-size: 28px;
          color: #545F78;
        }
      }
    }
    .choose-first {
      .top-nav {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px 0 20px;
        box-sizing: border-box;
        .input-group {
          margin-top: 20px;
          flex-wrap: wrap;
          .wrap {
            margin-right: 20px;
            margin-bottom: 20px;
          }
          .label {
            font-size: 14px;
            color: #545F78;
          }
        }
      }
      .have-sel {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
      .middle-nav {
        width: 25%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
      }
      .middle-r {
        width: 73%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .upload-style-i {
          margin-top: unset;
          position: relative;
        }
      }
      .third-nav {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .table {
          width: 100%;
          border: 1px solid #E4EAF9;
          border-radius: 7px;
          margin-top: 20px;
          display: flex;
          .first-item {
            width: 18%;
          }
          .second-item {
            width: 24%;
          }
          .third-item {
            flex: 1;
          }
          .tab-label {
            height: 46px;
            background: #F6F7FC;
            font-size: 16px;
            font-weight: bold;
            color: #1B2742;
          }
          .tab-val {
            width: 100%;
            height:80px ;
          }
        }
      }
      .company {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .more-del {
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E4EAF9;
          border-radius: 7px;
          margin-top: 12px;
          padding: 20px;
          box-sizing: border-box;
          position: relative;
          .font {
            width: 48px;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid #DBDEE6;
            border-radius: 50%;
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            &:hover {
              border-color: #FD5454;
              .iconfont {
                color: #FD5454;
              }
            }
            .iconfont {
              font-size: 26px;
            }
          }
          .input-nav {
            flex-wrap: wrap;
            .wrap {
              margin-bottom: 10px;
              margin-right: 20px;
            }
            .label {
              font-size: 14px;
              font-weight: bold;
              color: #1B2742;
            }
          }
          .check-nav {
            margin-top: 10px;
            display: flex;
            .label {
              font-size: 14px;
              font-weight: bold;
              color: #1B2742;
            }
            .last-btn {
              width: 100px;
              height: 30px;
              background: #FFFFFF;
              border: 1px solid #4877E8;
              border-radius: 6px;
              font-size: 11px;
              color: #4877E8;
              margin-left: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .end-btn {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .wrap {
          margin-left: 60px;
          .label {
            font-size: 14px;
            font-weight: bold;
            color: #1B2742;
          }
        }


      }
      .moreSign {
        margin-top: 20px;
        .select-it {
          width: 32%;
          height: 128px;
          background: #FFFFFF;
          border-radius: 12px;
          cursor: pointer;
          border: 2px solid #fff;
          box-sizing: border-box;
          &:hover {
            border: 2px solid #4877E8;
          }
          .icon-pic {
            font-size: 40px;
            color: #4877E8;
          }
          .pic-name {
            font-size: 21px;
            font-weight: bold;
            color: #1B2742;
            margin-left: 10px;
          }
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin-top: 20px;
      height: 110px;
      cursor: pointer;
      background: #FFFFFF;
      border-radius: 12px;
      border: 2px solid #DBDEE6;
      box-sizing: border-box;
      &:hover {
        border: 2px solid #4877E8;
      }
      .iconfont {
        font-size: 50px;
        color: #4877E8;
      }
      span {
        font-size: 28px;
        font-weight: bold;
        color: #1B2742;
        margin-left: 12px;
      }
    }
    .submit-btn-else {
      border: unset;
    }
    .upload-style-i {
      margin-top: 20px;
      .up-del {
        width: 170px;
        height: 228px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 10px;
        .up-img {
          width: 105px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .middle-finish {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        .finish-success {
          font-size: 16px;
          font-weight: bold;
          color: #4877E8;
          margin-top: 20px;
        }
        .change-status {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          .see {
            flex: 1;
            height: 30px;
            font-size: 14px;
            border: 1px solid #DBDEE6;
            cursor: pointer;
            &:hover {
              border-color: #1a9aef;
              color: #1a9aef;
            }
          }
        }
      }

      .up-btn {
        width: 90px;
        height: 30px;
        background: #4877E8;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 12px;
      }
    }
    .else-up {
      display: flex;
      .upload-style {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        border: 1px dashed #8a8f97;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }
      .pic-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 12px;
      }
      .img-list {
        width: 100px;
        height: 100px;
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
        .close {
          position: absolute;
          top: -15px;
          right: -15px;
          cursor: pointer;
          color: #dd0000;
          display: none;
        }
      }
      .img-list:hover .close {
        display: inline-block;
      }
    }
    .use-table {
      .pop-inside {
        width: 900px;
        height: 600px;
        background: white;
        border-radius: 8px;
        padding:  0 20px 20px 20px;
        box-sizing: border-box;
        .choose-it {
          width: 88px;
          height: 34px;
          color: white;
          font-size: 16px;
          background: #4877E8;
          border-radius: 6px;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
        }
        .close-it {
          position: absolute;
          right: 15px;
          top: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .nav-tips{
    font-size:12px;
    font-weight:unset;
    line-height:28px;
    margin-left:10px;
    color: #ff0000;
  }
</style>

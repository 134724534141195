<template>
  <div class="page-wrap">
    <div class="choose-first" v-if="showMe">
      <div class="top-change" @click="goBack">
        <div class="l-where">
          发起签署<span>/</span>批量签署
        </div>
        <div class="r-back" >
          <i class="iconfont icon-fanhui"></i>
        </div>
      </div>
      <div class="top-nav">
        <div class="nav-title">基本任务信息</div>
        <div class="input-group flex">
          <div class="wrap flex">
            <div class="label">任务名称：</div>
            <div class="r-find">
              <Input v-model="formItem.task_name" placeholder="请输入任务名称" style="width: 240px" />
            </div>
          </div>
          <div class="wrap flex">
            <div class="label">截至日期：</div>
            <div class="r-find">
              <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end" placeholder="请选择截至日期" style="width: 240px"></DatePicker>
            </div>
          </div>
          <div class="wrap flex">
            <div class="label">合同标签：</div>
            <div class="r-find">
              <Select v-model="formItem.label_id" multiple style="width:240px">
                <Option v-for="item in ticketList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div class="have-sel">
        <div class="middle-nav">
          <div class="nav-title">
            选择模板
            <Select @on-change="showMsg" filterable  clearable  v-model="formItem.template_id" style="width:210px;margin-left: 12px">
              <Option v-for="item in tempList" :value="item.id" :key="item.value">{{ item.template_name }}</Option>
            </Select>
          </div>
          <div class="upload-style-i" v-if="formItem.contract_imgs != ''">
            <div class="up-del middle-finish">
              <img src="../../../assets/imgs/123.png" height="58" width="46"/>
              <div class="finish-success">上传成功</div>
              <div class="change-status flex-c">
                <div class="see flex-c" @click="previewIt">预览</div>
                <div class="see flex-c" @click="delTem">删除</div>
              </div>
            </div>
          </div>
          <div class="upload-style-i" v-else>
            <Upload
                    :show-upload-list="false"
                    :headers='headers'
                    :action="imageAction"
                    class="up-del flex-c"
                    :on-format-error="handleFormatError"
                    :format="['jpg','jpeg','png','doc','docx','pdf']"
                    :on-success="upload_success">
              <div class="up-img">
                <img src="../../../assets/imgs/12.png"/>
              </div>
              <div class="up-btn flex-c">选择文件</div>
            </Upload>
          </div>
        </div>
        <div class="middle-r">
          <div class="nav-title">上传附件<span class="nav-tips">仅供查看，无法签署</span></div>

          <div style="display: flex;margin-top: 20px">
            <div class="upload-style-i" v-if="accessories_url != ''">
              <div class="up-del middle-finish">
                <img src="../../../assets/imgs/123.png" height="58" width="46"/>
                <div class="finish-success">上传成功</div>
                <div class="change-status flex-c">
                  <div class="see flex-c" @click="yulanPic">预览</div>
                  <div class="see flex-c" @click="delPic">删除</div>
                </div>
              </div>
            </div>
            <div class="upload-style-i" v-if="accessories_url == ''">
              <Upload
                      :show-upload-list="false"
                      :headers='headers'
                      :name="'img_file'"
                      :action="pictureAction"
                      class="up-del flex-c"
                      :on-format-error="handleFormatError"
                      :format="['jpg','jpeg','png','doc','docx','pdf']"
                      :on-success="upload_success_else">
                <div class="up-img">
                  <img src="../../../assets/imgs/12.png"/>
                </div>
                <div class="up-btn flex-c">选择附件</div>
              </Upload>
            </div>
            <div class="else-up">
              <div class="pic-wrap">
                <div class="img-list" v-for="(item,index) in accessoriesList" :key="index">
                  <img :src="item">
                  <div class="close" @click="delThis(index)"><Icon type="ios-close-circle-outline" size="30" /></div>
                </div>
                <div v-if="accessoriesList.length <=4">
                  <Upload
                          :show-upload-list="false"
                          :headers='headers'
                          :name="'img_file'"
                          :action="pictureAction"
                          :on-format-error="handleFormatError"
                          :max-size="3072"
                          :on-exceeded-size="errSize"
                          :format="['jpg','jpeg','png',]"
                          :on-success="uploadImgSuccess">
                    <div class="upload-style">
                      <Icon type="ios-add" size="60"/>
                      <Button size="small" type="primary">上传图片</Button>
                    </div>
                  </Upload>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="third-nav">
        <div class="nav-title">合同参与人</div>
        <div class="table">
          <div class="first-item">
            <div class="tab-label flex-c">参与主体</div>
            <div class="tab-val flex-c">
              <Select v-model="formItem.type" style="width:200px;">
                <Option v-for="item in selectList" :value="item.val" :key="item.val">{{ item.label }}</Option>
              </Select>
            </div>
          </div>
          <div class="second-item" v-show="formItem.type === 1">
            <div class="tab-label flex-c">企业名称</div>
            <div class="tab-val flex-c">
              <Input v-model="formItem.corporate_name" placeholder="请输入企业名称" style="width: 240px" />
            </div>
          </div>
          <div class="third-item">
            <div class="tab-label flex-c">经办人账号</div>
            <div class="tab-val flex-c">
              <div class="wrap flex" style="margin-right: 10px">
                <div class="label">真实姓名：</div>
                <div class="r-find">
                  <Input v-model="formItem.name" placeholder="请输入真实姓名" style="width: 200px" />
                </div>
              </div>
              <div class="wrap flex">
                <div class="label">手机号：</div>
                <div class="r-find">
                  <Input v-model="formItem.paraticipant_phone" type="number" placeholder="请输入手机号" style="width: 200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="third-nav">
        <div class="nav-title">签署要求</div>
        <div class="flex">
          签署者：
          <CheckboxGroup v-model="requirementElse" style="margin:0 40px 0 10px">
            <Checkbox :label="1">个人 </Checkbox>
            <Checkbox :label="2">企业</Checkbox>
          </CheckboxGroup>
          <div style="margin-right: 10px">签署时短信验证：</div>
          <Checkbox v-model="formItem.is_sms">启用</Checkbox>
          <div style="margin:0 10px 0 30px">企业用户盖章：</div>
          <Checkbox v-model="formItem.is_yinzhang">启用</Checkbox>
          <div class="wrap flex" style="margin-left: 30px">
            <div class="label">支持签署人数</div>
            <div class="r-find">
              <Input v-model="formItem.support_num" type="number" placeholder="（0为不限制）" style="width: 120px;margin-left: 12px;margin-right: 12px" />
            </div>
            <div class="label">人</div>
          </div>
        </div>
      </div>
      <div class="end-btn">
        <div class="submit-btn flex-c" @click="submitBatch">
          <i class="iconfont icon-faqiqianshu1"></i>
          <span>发起签署</span>
        </div>
      </div>
    </div>
    <div v-else>
      <Preview ref="preview" @titleSync="seeBack"></Preview>
    </div>
  </div>
</template>

<script>
  import {getCookies} from '@/utils/helper'
  import config from '@/config/index'
  import Preview from '../../../components/preview/index'
  import {createBatch,templist, edittempupdate,  delEnclosure, searchList, info, deletetmp} from '../../../api/interFace'
  export default {
    name: "index",
    components:{Preview},
    data(){
      return{
        formItem: {
          task_name: '',
          name: '',
          is_sms: false,
          document_type: '',
          upload_type: '',
          start_time: '',
          end_time: '',
          paraticipant_phone: '',
          is_yinzhang:false,
          support_num: 0,
          contract_imgs: [],
          corporate_name: '',
          requirement: '',
          type:0,
          template_id:'',
          label_id:''
        },
        timeVal : [],
        ticketList:'',
        accessories_file:'',
        showDetail:true,
        showMe:true,
        accessoriesList:[],
        accessories_img:[],
        requirementElse:[1],
        imageAction:config.apiUrl + '/adminapi/common/uploads',
        pictureAction:config.apiUrl + '/adminapi/contract/upload_enclosure',
        headers:{'Authori-zation' : getCookies('token')},
        modelSelect:'',
        selectList:[
          {
            val:0,
            label:'个人'
          },
          {
            val:1,
            label:'企业'
          }
        ],
        showType:'',
        tempList:[],
        accessories_url:'',
        submiting:false
      }
    },
    created() {
      this.getTem()
      this.getTickets()
    },
    methods:{
      changeTime(e){
        this.formItem.start_time = e[0]
        this.formItem.end_time = e[1]
      },
      seeBack(){
        this.showMe = true
      },
      getTickets(){
        searchList().then(res=>{
          this.ticketList = res.data || []
        }).catch(res=>{
          console.log(res)
        })
      },
      upload_success_else (e){
        this.accessories_file = e.data.img_url
        this.accessories_url = e.data.src
        this.$Message.destroy()
      },
      previewIt(){
        this.showMe = false
        this.$nextTick(()=>{
          this.$refs.preview.openIt(this.formItem)
        })
      },
      delTem() {
        deletetmp({contract_path: this.formItem.contract_imgs}).then(res => {
          this.$Message.success(res.msg)
          this.formItem.template_id = ''
        }).catch(res => {
          this.$Message.error(res.msg)
        })
        this.formItem.upload_type = ''
        this.formItem.contract_imgs = []
        this.formItem.document_type = ''
      },
      //选择模板
      showMsg(e){
        if(!e){
          return
        }
        const msg = this.$Message.loading({
          content: '文件较大正上传中，请等待...',
          duration: 0
        });
        info({id:e}).then(async res => {
          this.formItem.document_type = res.data.document_type
          this.formItem.upload_type = res.data.upload_type
          this.formItem.contract_imgs = res.data.contract_imgs
          setTimeout(msg, 100);
        })
      },
      submitBatch(){
        if(this.requirementElse[0] === 1)this.formItem.requirement = 0
        if(this.requirementElse[0] === 2)this.formItem.requirement = 1
        if(this.requirementElse.length == 2)this.formItem.requirement = 2
        if(this.requirementElse.length == 0) {
          this.$Message.error('签署条件中的签署者必须选择一个')
          return
        }
        if (this.formItem.support_num == 0) {
          this.$Message.error('请填写签署人数')
          return
        }
        this.formItem.sms_status = this.formItem.is_sms ? '1' : '0'
        this.formItem.support_yinzhang = this.formItem.is_yinzhang ? '1' : '0'
        if(this.license_pic === '') {
          this.$Message.error('请上传合同后再次提交')
          return
        }
        let s = this.formItem.label_id.toString()
        this.formItem.label_id = s
        this.formItem.accessories_url = this.accessories_url
        this.formItem.accessories_img = String(this.accessories_img)
        if (this.submiting) {
          return
        }

        this.submiting = true
        this.$Message.loading({
          content: '发起中',
          duration: 0
        });

        createBatch(this.formItem).then(res=>{
          this.submiting = false
          this.$Message.destroy()
          this.$Message.success(res.msg)
          this.reset()
        }).catch(res=>{
          this.submiting = false
          this.$Message.destroy()
          this.$Message.error(res.msg)
        })
      },
      getTem(){
        templist({status:'all'}).then(async res => {
          this.tempList = res.data.data
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      },
      reset(){
        this.formItem = {
          task_name: '',
          name: '',
          is_sms: false,
          document_type: '',
          upload_type: '',
          start_time: '',
          end_time: '',
          paraticipant_phone: '',
          support_num: 0,
          is_yinzhang:false,
          contract_imgs: [],
          corporate_name: '',
          requirement: '',
          type: 0,
          template_id: '',
          label_id:'',
        }
        this.accessories_url = []
        this.accessories_img = []
        this.requirementElse = []
        this.timeVal = []
      },
      goBack(){
        this.reset()
        this.$emit('goBack')
      },
      // 文件类型上传失败
      handleFormatError() {
        this.$Message.warning('暂不支持上传此类型文件')
      },
      yulanPic(){
        window.open(this.accessories_file)
      },
      delPic() {
        delEnclosure({img_url:this.accessories_url}).then(res=>{
          this.$Message.success(res.msg);
          this.accessories_file = ''
          this.accessories_url = ''
        })
      },
      // 判断类型上传文件
      upload_success(e) {
        const msg = this.$Message.loading({
          content: '上传中...',
          duration: 0
        });
        // //获取合同 HTML
        edittempupdate({path:e.data.src}).then(async res =>{
          this.formItem.document_type= res.data.document_type
          this.formItem.upload_type= res.data.upload_type
          this.formItem.contract_imgs = res.data.imgs
          this.$Message.success(e.msg)
          this.license_pic = e.data.src
          setTimeout(msg, 100);
          this.$Message.destroy()
        }).catch(res => {
          this.$Message.error(res.msg)
          setTimeout(msg, 100);
        })
      },
      upload_success_most(){

      },
      errSize(){
        this.$Message.error('上传图片不得超过3M');
      },
      //添加图片上传图片成功
      uploadImgSuccess (e) {
        this.accessoriesList.push(e.data.img_url)
        this.accessories_img.push(e.data.src)
        this.$Message.destroy()
        this.$Message.success('上传成功');
      },
      delThis(index){
        let val = this.accessories_img[index]
        delEnclosure({img_url:val}).then(res=>{
          this.$Message.success(res.msg);
          this.accessoriesList.splice(index,1)
          this.accessories_img.splice(index,1)
        })
      },
      changeType(type){
        this.showDetail = false
        this.showType = type
      }
    }
  }
</script>

<style scoped lang="scss">

  .page-wrap {
    .top-change {
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 30px;
      box-sizing: border-box;
      margin-bottom: 20px;
      cursor: pointer;
      height: 60px;
      .l-where {
        //position: absolute;
        //left: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 18px;
        font-weight: bold;
        color: #1B2742;
        span {
          color: #4877E8;
        }
      }
      .r-back {
        .iconfont {
          //position: absolute;
          //right: 30px;
          //top: 50%;
          //transform: translateY(-50%);
          font-size: 26px;
          cursor: pointer;
          &:hover {
            color: #4877E8;
          }
        }
      }
    }
    .first-in {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      .check-item {
        width: 280px;
        height: 340px;
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        border: 2px solid #fff;
        box-sizing: border-box;
        &:hover {
          border: 2px solid #4877E8;
        }
        .iconfont {
          font-size: 80px;
          color: #6D92ED;
        }
        .word {
          margin-top: 12px;
          font-size: 28px;
          color: #545F78;
        }
      }
    }
    .choose-first {
      .top-nav {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 20px 20px 0 20px;
        box-sizing: border-box;
        .nav-title {
        }

        .input-group {
          margin-top: 20px;
          flex-wrap: wrap;
          .wrap {
            margin-right: 20px;
            margin-bottom: 20px;
          }
          .label {
            font-size: 14px;
            color: #545F78;
          }
        }
      }
      .have-sel {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
      .middle-nav {
        width: 25%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
      }
      .middle-r {
        width: 73%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .upload-style-i {
          margin-top: unset;
        }
      }
      .third-nav {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .table {
          width: 100%;
          border: 1px solid #E4EAF9;
          border-radius: 7px;
          margin-top: 20px;
          display: flex;
          .first-item {
            width: 18%;
          }
          .second-item {
            width: 24%;
          }
          .third-item {
            flex: 1;
          }
          .tab-label {
            height: 46px;
            background: #F6F7FC;
            font-size: 16px;
            font-weight: bold;
            color: #1B2742;
          }
          .tab-val {
            width: 100%;
            height:80px ;
          }
        }
      }
      .company {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .more-del {
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E4EAF9;
          border-radius: 7px;
          margin-top: 12px;
          padding: 20px;
          box-sizing: border-box;
          .input-nav {
            .wrap {
              flex: 1;
            }
            .label {
              font-size: 14px;
              font-weight: bold;
              color: #1B2742;
            }
          }
          .check-nav {
            margin-top: 20px;
            display: flex;
            .label {
              font-size: 14px;
              font-weight: bold;
              color: #1B2742;
            }
            .last-btn {
              width: 100px;
              height: 30px;
              background: #FFFFFF;
              border: 1px solid #4877E8;
              border-radius: 6px;
              font-size: 11px;
              color: #4877E8;
              margin-left: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .end-btn {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .wrap {
          margin-left: 60px;
          .label {
            font-size: 14px;
            font-weight: bold;
            color: #1B2742;
          }
        }


      }
      .moreSign {
        margin-top: 20px;
        .select-it {
          width: 32%;
          height: 128px;
          background: #FFFFFF;
          border-radius: 12px;
          cursor: pointer;
          border: 2px solid #fff;
          box-sizing: border-box;
          &:hover {
            border: 2px solid #4877E8;
          }
          .icon-pic {
            font-size: 40px;
            color: #4877E8;
          }
          .pic-name {
            font-size: 21px;
            font-weight: bold;
            color: #1B2742;
            margin-left: 10px;
          }
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin-top: 20px;
      height: 110px;
      cursor: pointer;
      background: #FFFFFF;
      border-radius: 12px;
      border: 2px solid #DBDEE6;
      box-sizing: border-box;
      &:hover {
        border: 2px solid #4877E8;
      }
      .iconfont {
        font-size: 50px;
        color: #4877E8;
      }
      span {
        font-size: 28px;
        font-weight: bold;
        color: #1B2742;
        margin-left: 12px;
      }
    }
    .submit-btn-else {
      border: unset;
    }
    .upload-style-i {
      margin-top: 20px;
      .up-del {
        width: 170px;
        height: 228px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 10px;
        .up-img {
          width: 105px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .up-btn {
        width: 90px;
        height: 30px;
        background: #4877E8;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 12px;
      }
    }
    .else-up {
      display: flex;
      .upload-style {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        border: 1px dashed #8a8f97;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }
      .pic-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 12px;
      }
      .img-list {
        width: 100px;
        height: 100px;
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
        .close {
          position: absolute;
          top: -15px;
          right: -15px;
          cursor: pointer;
          color: #dd0000;
          display: none;
        }
      }
      .img-list:hover .close {
        display: inline-block;
      }
    }
    .middle-finish {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      .finish-success {
        font-size: 16px;
        font-weight: bold;
        color: #4877E8;
        margin-top: 20px;
      }
      .change-status {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        .see {
          flex: 1;
          height: 30px;
          font-size: 14px;
          border: 1px solid #DBDEE6;
          cursor: pointer;
          &:hover {
            border-color: #1a9aef;
            color: #1a9aef;
          }
        }
      }
    }
  }

  .nav-tips{
    font-size:12px;
    font-weight:unset;
    line-height:28px;
    margin-left:10px;
    color: #ff0000;
  }
</style>
